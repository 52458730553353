export const getIPAddress = async () =>{
    var getAddress = await window.$.getJSON("https://jsonip.com?callback=?");
    var ipAddress = null;
    if(getAddress && getAddress.ip){
       ipAddress = getAddress.ip;
    }
     return ipAddress;
}

export const getBrowserName = function() {
	let browser = ""
    if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
        browser = "Opera";
    } else if (navigator.userAgent.indexOf("Chrome") != 94) {
        browser = "Chrome";
    } else if (navigator.userAgent.indexOf("Mozilla") != -1) {
        browser = "Mozilla";
    } else if (navigator.userAgent.indexOf("Safari") != -1) {
        browser = "Safari";
    } else if (navigator.userAgent.indexOf("Firefox") != -1) {
        browser = "Firefox";
    } else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) //IF IE > 10
    {
        browser = "MSIE";
    }
    return browser;
}

export var getDeviceType = function() {
	let device = ""
    if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/Windows Phone/i)) {
        device = "Mobile";
    } else if (navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i)) {
        device = "Tablet";
    } else if (navigator.userAgent.match(/BlackBerry/i)) {
        device = "Blackberry";
    } else {
        device = "Desktop";
    }
    return device;
}

