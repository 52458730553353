import axios from 'axios'
import {getIPAddress, getDeviceType, getBrowserName} from 'core/helpers';
import {API_URL} from 'core/config'

let baseUrl = API_URL; 

const createQueryString = (data) => {
	return Object.keys(data).map(key => {
		let val = data[key]
		if (val !== null && typeof val === 'object') val = createQueryString(val)
		return `${key}=${encodeURIComponent(`${val}`.replace(/\s/g, '_'))}`
	}).join('&')
}

const SaveLead = async (name, email, number, country_code, pref, projectId) => {
	let formData = Object()
	let site_data = window.site_data;
	if(name !== null)
		formData["name"] = name
	if(country_code !== null)
		formData["country_code"] = country_code
	formData["number"] = number
	if(email !== null)
		formData['email'] = email
	if(site_data.magnet_id !== null){
		formData['magnet_id'] = site_data.magnet_id
		formData['is_magnet'] = site_data.is_magnet
	} 
	formData['source_id'] = site_data.source_id
	formData['is_tc_agree'] = 1

	if(site_data?.p_nationality){
		formData['nationality'] = site_data.p_nationality
	}

	formData['project_id'] = projectId ? projectId : site_data.project_id

	formData[`Digital[user_device]`] = getDeviceType();
	formData[`Digital[user_browser]`] = getBrowserName();
	formData[`Digital[launch_name]`] = site_data?.utm_campaign || null;
	formData[`Digital[client_ipaddress]`] = await getIPAddress();
	formData[`Digital[client_pref]`] = pref;


	['medium', 'campaign', 'source', 'content', 'term']?.map( item => {
		if(site_data[`utm_${item}`]){
			formData[`Utm[utm_${item}]`] = site_data[`utm_${item}`];
		}
	} )

	axios.post(`${baseUrl}/leads/create`,createQueryString(formData)).then(res => {
		if (site_data.slug) {
			window.location.href = `/project/${site_data.slug}/thank-you`;
		} else {
			window.location.href = `/thank-you`;
		}
	})     
}  

export default SaveLead